import { PreOrderStatuses } from 'src/app/core/domain/bulk-pre-order/pre-order-status.model';

export enum LocalizedPreOrderStatuses {
  INPROGRESS = 'PRE_ORDER_PAGE.INPROGRESS_REQUEST_STATUS',
  COMPLETED = 'PRE_ORDER_PAGE.COMPLETED_REQUEST_STATUS',
  INCOMPLETE = 'PRE_ORDER_PAGE.INCOMPLETE_REQUEST_STATUS',
  AWAITING_APPROVAL = 'PRE_ORDER_PAGE.AWAITING_APPROVAL_REQUEST_STATUS',
  AWAITING_EXTERNAL_PRODUCT_APPROVAL = 'PRE_ORDER_PAGE.AWAITING_APPROVAL_EXTERNAL_REQUEST_STATUS',
  REJECTED = 'PRE_ORDER_PAGE.REJECTED_REQUEST_STATUS',
  DOWN_PAYMENT_OFFERED = 'PRE_ORDER_PAGE.DOWN_PAYMENT_OFFERED_STATUS',
  MONEY_TRANSFERRED_APPROVED = 'PRE_ORDER_PAGE.MONEY_TRANSFERRED_APPROVED_STATUS',
  STARTED = 'PRE_ORDER_PAGE.STARTED_STATUS',
  DOWN_PAYMENT_REJECTED_BY_MERCHANT = 'PRE_ORDER_PAGE.DOWN_PAYMENT_REJECTED_BY_MERCHANT_STATUS',
  DOWN_PAYMENT_AGREED = 'PRE_ORDER_PAGE.DOWN_PAYMENT_AGREED_STATUS',
}

export interface LocalizedSummaryInfoModel {
  status: LocalizedPreOrderStatuses;
  numberOfRequests: number;
}

export const LocalizedPreOrderStatusesMapper = (status: string): LocalizedPreOrderStatuses => {
  switch (status) {
    case PreOrderStatuses.STARTED:
      return LocalizedPreOrderStatuses.STARTED;
    case PreOrderStatuses.AWAITING_EXTERNAL_PRODUCT_APPROVAL:
      return LocalizedPreOrderStatuses.AWAITING_EXTERNAL_PRODUCT_APPROVAL;
    case PreOrderStatuses.AWAITING:
      return LocalizedPreOrderStatuses.AWAITING_APPROVAL;
    case PreOrderStatuses.INPROGRESS:
      return LocalizedPreOrderStatuses.INPROGRESS;
    case PreOrderStatuses.REJECTED:
      return LocalizedPreOrderStatuses.REJECTED;
    case PreOrderStatuses.MONEY_TRANSFERRED_APPROVED:
      return LocalizedPreOrderStatuses.MONEY_TRANSFERRED_APPROVED;
    case PreOrderStatuses.DOWN_PAYMENT_REJECTED_BY_MERCHANT:
      return LocalizedPreOrderStatuses.DOWN_PAYMENT_REJECTED_BY_MERCHANT;
    case PreOrderStatuses.DOWN_PAYMENT_OFFERED:
      return LocalizedPreOrderStatuses.DOWN_PAYMENT_OFFERED;
    case PreOrderStatuses.COMPLETED:
      return LocalizedPreOrderStatuses.COMPLETED;
    case PreOrderStatuses.INCOMPLETE:
      return LocalizedPreOrderStatuses.INCOMPLETE;
    case PreOrderStatuses.DOWN_PAYMENT_AGREED:
      return LocalizedPreOrderStatuses.DOWN_PAYMENT_AGREED;
    default:
      throw new Error();
  }
};
